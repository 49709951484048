import React from 'react'

import './style.scss'

export default function Layout(props: React.PropsWithChildren): React.ReactElement {
  return (
    <div className="layout"> 
      <main>{props.children}</main>
    </div>
  )
}
