import React from 'react'

import Header, { HeaderProps } from './components/Header'
import Layout from './components/Layout'
import Projects, { ProjectsProps } from './components/Projects'
import Skills, { SkillsProps } from './components/Skills'
import Work, { WorkProps } from './components/Work'

const headerProps: HeaderProps = {
  email: 'od.deanda@gmail.com',
  fullName: 'Omar David De Anda Hernández',
  name: 'David De Anda',
  phoneNumber: '+52 449 406 2713',
  profilePic: 'https://avatars.githubusercontent.com/u/8797222?v=4',
  showContact: false,
  socials: [
    {
      tagname: '@omarandstuff',
      url: 'https://github.com/omarandstuff',
      icon: ['fab', 'github']
    },
    {
      tagname: '@omarandstuff',
      url: 'https://linkedin.com/in/omarandstuff',
      icon: ['fab', 'linkedin']
    },
    {
      tagname: '@arthatsnothere',
      url: 'https://instagram.com/arthatsnothere',
      icon: ['fab', 'instagram']
    },
    {
      tagname: 'omardeanda.com',
      url: 'https://omardeanda.com',
      icon: ['fas', 'mouse-pointer']
    }
  ]
}

const skillsProps: SkillsProps = {
  enableSkillIconColors: false,
  skills: [
    { name: 'Javascript', proficiency: 'advanced', years: 10, icon: ['fab', 'js'], iconColor: '#f0db4f' },
    { name: 'Typescript', proficiency: 'proficient', years: 6, icon: ['fab', 'js'], iconColor: '#007acc' },
    { name: 'React', proficiency: 'advanced', years: 8, icon: ['fab', 'react'], iconColor: '#61dafb' },
    { name: 'React Native', proficiency: 'proficient', years: 5, icon: ['fab', 'react'], iconColor: '#61dafb' },
    { name: 'NodeJS', proficiency: 'advanced', years: 8, icon: ['fab', 'node-js'], iconColor: '#68a063' },
    { name: 'CI/CD', proficiency: 'advanced', years: 6, icon: ['fas', 'code'], iconColor: '#623ce4' },
    { name: 'Ruby', proficiency: 'proficient', years: 8, icon: ['fas', 'gem'], iconColor: '#cc342d' },
    { name: 'Ruby on Rails', proficiency: 'proficient', years: 8, icon: ['fas', 'gem'], iconColor: '#cc342d' },
    { name: 'Docker', proficiency: 'proficient', years: 5, icon: ['fab', 'docker'], iconColor: '#2496ed' },
    { name: 'Terraform', proficiency: 'proficient', years: 4, icon: ['fas', 'code'], iconColor: '#623ce4' },
    { name: 'AWS', proficiency: 'proficient', years: 4, icon: ['fab', 'aws'], iconColor: '#ff9900' },
    { name: 'Blender', proficiency: 'proficient', years: 7, icon: ['fas', 'cube'], iconColor: '#f5792a' },
    { name: 'Unity 3D', proficiency: 'intermediate', years: 3, icon: ['fab', 'unity'], iconColor: '#000000' },
    { name: 'WebGL', proficiency: 'entry', years: 2, icon: ['fas', 'cube'], iconColor: '#ffffff' },
    { name: 'C#', proficiency: 'entry', years: 2, icon: ['fas', 'code'], iconColor: '#239120' }
  ]
}

const workProps: WorkProps = {
  positions: [
    {
      company: 'Nuvocargo',
      name: 'Software Engineer',
      from: new Date('2022-07-05'),
      time: '1 year, 2 months',
      description: 'We work solving border crossing problems at the Mexico/US border, I work on a Ruby on Rails monolith, using view components.',
      location: 'Mexico City, Mexico (Remote)',
      modality: 'Full Time'
    },
    {
      company: 'Tango',
      name: 'Senior Software Engineer',
      from: new Date('2019-08-01'),
      to: new Date('2022-08-05'),
      time: '3 years',
      description:
        'As a consultancy company, I worked on a lot of different projects, from a React Native app for a startup, to a Ruby on Rails monolith for a big company, I also worked mostly rebuilding and automating the infrastructure of another project, using Terraform, Docker, and AWS.',
      location: 'Colima, Mexico (Remote)',
      modality: 'Full Time'
    },
    {
      company: 'Estudemo CI/CD',
      name: 'Founder',
      from: new Date('2018-08-01'),
      to: new Date('2019-08-01'),
      time: '1 year',
      description:
        'I founded a company with some other people, we were building a CI/CD platform that ran workflows on the user hardware, we were using NodeJS, React, Docker, and AWS.',
      location: 'Aguascalientes, Mexico (Remote)',
      modality: 'Full Time'
    },
    {
      company: 'Magma Labs',
      name: 'Senior Software Engineer',
      from: new Date('20178-02-01'),
      to: new Date('2018-08-01'),
      time: '6 months',
      description: 'I worked on a Ruby on Rails monolith, using React and Redux for the frontend, and a lot of other technologies for the backend, like Sidekiq, Redis, etc.',
      location: 'Colima, Mexico (Remote)',
      modality: 'Full Time'
    },
    {
      company: 'Tango Source',
      name: 'Software Engineer',
      from: new Date('2017-02-01'),
      to: new Date('2018-02-01'),
      time: '1 year',
      description: 'I worked on a Ruby on Rails monolith, using Angular and Bootstrap for the frontend.',
      location: 'Colima, Mexico (Remote)',
      modality: 'Full Time'
    },
    {
      company: 'Lion Systems',
      name: 'Software Engineer',
      from: new Date('2014-08-01'),
      to: new Date('2017-02-01'),
      time: '2 years, 6 months',
      description:
        'I worked with php, using Laravel and AngularJS, I was introduced to Ruby on Rails, and I worked on a couple of projects using it. We also worked using Unity 3D, and Blender.',
      location: 'Aguascalientes, Mexico (Remote)',
      modality: 'Full Time'
    }
  ]
}

const projectsProps: ProjectsProps = {
  projects: [
    {
      name: 'universal-packages',
      description: 'A group of packages that consolidates the universal-core package that serves as a universal framework for building any kind of node and frontend application.',
      url: 'https://github.com/universal-packages'
    },
    {
      name: 'Cubiline',
      description: 'A game made in Objective-C, using OpenGL, a 3d concept of the classic snake game.',
      url: 'https://apps.apple.com/us/app/cubiline/id959482080'
    }
  ]
}

export default function App(): React.ReactElement {
  return (
    <Layout>
      <Header {...headerProps} />
      <Skills {...skillsProps} />
      <Work {...workProps} />
      <div className="page-break" />
      <Projects {...projectsProps} />
    </Layout>
  )
}
