import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import './style.scss'

export interface HeaderProps {
  email: string
  fullName?: string
  name: string
  phoneNumber: string
  profilePic: string
  showContact?: boolean
  socials: {
    tagname: string
    url: string
    icon: IconProp
  }[]
}

export default function Header(props: HeaderProps): React.ReactElement {
  return (
    <section className="header">
      <section className="profile-picture">
        <img src={props.profilePic} alt="profile-picture" />
      </section>
      <section className="info">
        <section className="name">
          <h1>{props.name}</h1>
          {props.fullName ? <h4>{props.fullName}</h4> : null}
        </section>

        {props.showContact ? (
          <section className="contact">
            <a href={`mailto:${props.email}`}>
              <FontAwesomeIcon icon={['fas', 'envelope']}></FontAwesomeIcon>
              {props.email}
            </a>
            <a href={`tel:${props.phoneNumber}`}>
              <FontAwesomeIcon icon={['fas', 'phone']}></FontAwesomeIcon>
              {props.phoneNumber}
            </a>
          </section>
        ) : null}

        <section className="social">
          {props.socials.map((social, index) => {
            return (
              <a key={index} href={social.url} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={social.icon}></FontAwesomeIcon>
                {social.tagname}
              </a>
            )
          })}
        </section>
      </section>
    </section>
  )
}
