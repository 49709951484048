import React from 'react'

import './style.scss'

export interface WorkProps {
  positions: {
    name: string
    company: string
    from: Date
    to?: Date
    time: string
    description: string
    location: string
    modality: string
  }[]
}

export default function Work(props: WorkProps): React.ReactElement {
  return (
    <section className="work">
      <section className="head">
        <h1>Work</h1>
      </section>
      <section className="list">
        {props.positions.map((position, index) => (
          <section className="position" key={index}>
            <section className="title">
              <h2>{position.company}</h2>
              <h3>{position.name}</h3>
            </section>
            <section className="time">
              <span>{formatDate(position.from)}</span>
              <span>to</span>
              <span>{formatDate(position.to)}</span>
              <span>({position.time})</span>
            </section>
            <section className="location">
              <span>{position.location}</span>
              <span>({position.modality})</span>
            </section>
            <section className="description">
              <p>{position.description}</p>
            </section>
          </section>
        ))}
      </section>
    </section>
  )
}

function formatDate(date?: Date): string {
  if (!date) return 'Present'

  return date.toLocaleDateString('en-us', { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' })
}
