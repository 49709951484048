import React from 'react'

import './style.scss'

export interface ProjectsProps {
  projects: {
    name: string
    description: string
    url: string
  }[]
}

export default function Projects(props: ProjectsProps): React.ReactElement {
  return (
    <section className="projects">
      <section className="head">
        <h1>Projects</h1>
      </section>
      <section className="list">
        {props.projects.map((project, index) => (
          <section className="project" key={index}>
            <section className="name">
              <h2>{project.name}</h2>
            </section>
            <section className="url">
              <a href={project.url} target="_blank" rel="noreferrer">
                {project.url}
              </a>
            </section>
            <section className="description">
              <p>{project.description}</p>
            </section>
          </section>
        ))}
      </section>
    </section>
  )
}
