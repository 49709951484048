import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import './style.scss'

export type Proficiency = 'entry' | 'intermediate' | 'proficient' | 'advanced' | 'none'

export interface SkillsProps {
  enableSkillIconColors?: boolean
  skills: {
    name: string
    proficiency: Proficiency
    years: number
    icon: IconProp
    iconColor?: string
  }[]
}

const COLOR_PROFICIENCY_MAP: Record<Proficiency, string> = {
  entry: '#0497cc',
  intermediate: '#04cc5b',
  proficient: '#f5e342',
  advanced: '#212121',
  none: '#21212142'
}

const STAR_PROFICIENCY_MAP: Record<Proficiency, Proficiency[]> = {
  entry: ['entry', 'none', 'none', 'none'],
  intermediate: ['intermediate', 'intermediate', 'none', 'none'],
  proficient: ['proficient', 'proficient', 'proficient', 'none'],
  advanced: ['advanced', 'advanced', 'advanced', 'advanced'],
  none: ['none', 'none', 'none', 'none']
}

export default function Skills(props: SkillsProps): React.ReactElement {
  const chunks = arrayInChunks(props.skills, 5)

  return (
    <section className="skills">
      <section className="head">
        <h1>Skills</h1>
      </section>
      <section className="list">
        {chunks.map((chunk, i) => (
          <div className="chunk" key={i}>
            <table>
              <tbody>
                {chunk.map((skill, j) => (
                  <tr className="skill" key={j}>
                    <td className="icon">
                      <FontAwesomeIcon icon={skill.icon} color={(props.enableSkillIconColors && skill.iconColor) || ''} />
                    </td>
                    <td className="name">{skill.name}</td>
                    <td className="years">
                      {STAR_PROFICIENCY_MAP[skill.proficiency].map((proficiency, k) => (
                        <FontAwesomeIcon key={k} icon={['fas', 'star']} color={COLOR_PROFICIENCY_MAP[proficiency]} />
                      ))}
                      {skill.years} years
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </section>
      <section className="foot">
        {['advanced', 'proficient', 'intermediate', 'entry'].map((proficiency, i) => (
          <span key={i}>
            {STAR_PROFICIENCY_MAP[proficiency].map((current, k) => (
              <FontAwesomeIcon key={k} icon={['fas', 'star']} color={COLOR_PROFICIENCY_MAP[current]} />
            ))}
            {capitalize(proficiency)}
          </span>
        ))}
        <span></span>
      </section>
    </section>
  )
}

function arrayInChunks<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
